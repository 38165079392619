import React from 'react'
import '../../../stylesheets/base/_helpdesk.scss'

function Script() {


    return (
        <div class="code-contain">
            <div><h3>Copy & Paste:</h3>
            <section  class='code'>

       <p style={{marginBottom: '1rem'}}>{`function sendFormByEmail(e)`}</p>

            <p style={{marginLeft: '1rem', marginBottom: '1rem'}}>{`{`}</p>

                <div style={{marginLeft: '2rem'}}>
                    
                <p style={{marginBottom: '1rem'}}>{`var email = "forms@workhub.com";`}</p>

                <p>{`var subject = 0;`}</p>
                <p>{`var doc = SpreadsheetApp.getActiveSpreadsheet();`}</p>
                <p style={{marginBottom: '1rem'}}>{`subject = doc.getName();`}</p>

                <p>{`var message = "*contents*<p><b><u>" + subject + "</u></b></p><br>";`}</p>
                <p>{`var s = SpreadsheetApp.getActive().getSheetByName("Form responses 1");`}</p>
                <p>{`var headers = s.getRange(1,1,1,s.getLastColumn()).getValues()[0];`}</p>
                <p>{`for(var i in headers) {`}</p></div>
                <div style={{marginLeft: '3rem'}}>
                    <p style={{marginLeft: '1rem'}}>{`if(headers[i]!=null && e!=null)`}</p>
                    <p style={{marginLeft: '1.5rem'}}>{`message += "<p><b>" + headers[i].toString() + "</b><br>"`}</p>
                    <p style={{marginLeft: '2rem'}}>{`+ e.namedValues[headers[i]].toString() + "</p>";`}</p>
                    <p style={{marginBottom: '1rem'}}>{`}`}</p></div>

                <p style={{marginBottom: '1rem'}}>{`MailApp.sendEmail(email, subject, "", {htmlBody: message});`}</p>

            <p style={{marginLeft: '1rem'}}>{`}`}</p>

</section></div>
        </div>
      
    )
}

export default Script

// function sendFormByEmail(e)

//    {  

//      var email = "webforms@safetysync.com";

//      var subject = 0;
//      var doc = SpreadsheetApp.getActiveSpreadsheet();
//      subject = doc.getName();

//      var message = "*contents*<p><b><u>" + subject + "</u></b></p><br>";
//      var s = SpreadsheetApp.getActive().getSheetByName("Form responses 1");
//      var headers = s.getRange(1,1,1,s.getLastColumn()).getValues()[0];
//      for(var i in headers) {
//       if(headers[i]!=null && e!=null)
//            message += "<p><b>" + headers[i].toString() + "</b><br>"
//                   + e.namedValues[headers[i]].toString() + "</p>";
//      }  

//      MailApp.sendEmail(email, subject, "", {htmlBody: message});

//    }
